import { Children, useCallback } from 'react';

import { Button } from 'components/widget/Button';
import { Locking } from 'model/Locking';
import { useRecoilValue } from 'recoil';
import { userInfoState } from 'recoil/user/atom';
import { isUserRole } from 'util/commonUtil';
import { decryptString } from 'util/encryptUtil';

type LotoOrderConclusionTableProps = {
  dataList: Locking[];
  isUnlockApprove: boolean;
  onOpenAgentSettingModal?: (locking: Locking) => void;
};

export default function LotoOrderConclusionTable({
  dataList,
  isUnlockApprove = false,
  onOpenAgentSettingModal = (locking) => {},
}: LotoOrderConclusionTableProps) {
  const { role } = useRecoilValue(userInfoState);
  const getAgentSettingButton = useCallback(
    (locking: Locking) => {
      if (locking.unlockRegDate) {
        return locking.unlockRegDate;
      }

      if (isUserRole(decryptString(role))) {
        return locking.transUserName ? `대리인(${locking.transUserName})` : '';
      }

      const btnName = !locking.transUserName
        ? '대리인 지정'
        : `대리인 변경(${locking.transUserName})`;
      return isUnlockApprove ? (
        <Button
          name={btnName}
          className={'btn-type-03'}
          onClick={() => onOpenAgentSettingModal(locking)}
        />
      ) : (
        '-'
      );
    },
    [isUnlockApprove],
  );

  return (
    <table className={'tbl-type-04 no-img'}>
      <caption>{'조회내역'}</caption>
      <colgroup>
        <col width={10} />
        <col />
        <col />
        <col />
        <col width={100} />
        <col />
        <col />
        <col />
      </colgroup>
      <thead>
        <tr>
          <th>{'No.'}</th>
          <th>{'Locker'}</th>
          <th>{'설비 명'}</th>
          <th>{'부착자'}</th>
          <th>{'전화번호'}</th>
          <th>{'부착일시'}</th>
          <th>{'제거자'}</th>
          <th>{'제거일시'}</th>
        </tr>
      </thead>
      <tbody>
        {Children.toArray(
          dataList.map((conclusion, index) => (
            <tr>
              <td className={'ta-center'}>{index + 1}</td>
              <td>{conclusion.lockerName ?? '-'}</td>
              <td>
                {`[${conclusion.equipCode}]`}
                &nbsp;
                {conclusion.equipName ?? '-'}
              </td>
              <td className={'ta-center'}>{conclusion.lockUserName ?? '-'}</td>
              <td className={'ta-center'}>{conclusion.lockUserPhone ?? '-'}</td>
              <td className={'ta-center'}>{conclusion.lockRegDate ?? '-'}</td>
              <td className={'ta-center'}>
                {conclusion.unlockUserName ?? '-'}
              </td>
              <td className={'ta-center'}>
                {getAgentSettingButton(conclusion)}
              </td>
            </tr>
          )),
        )}
      </tbody>
    </table>
  );
}
