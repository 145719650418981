import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';

import { BaseResp } from 'api/BaseResp';
import {
  getLotoOrder,
  useApproveDeleteMutation,
  useCancelApproveDeleteMutation,
  useCancelConfirmAttachMutation,
  useConfirmAttachMutation,
  useConfirmDeleteMutation,
  useSetUnlockAgentMutation,
} from 'api/lotoOrderApi';
import { userKeys } from 'api/queryKeys/queryKeys';
import { Locking } from 'model/Locking';
import { ORDER_EVENT_TYPE } from 'types/enumType';
import { customStyles, generatePopupContent } from 'util/styleUtil';

import LotoOrderConclusionTable from './LotoOrderConclusionTable';
import LotoOrderInfo from './LotoOrderInfo';
import UnlockAgentSettingModal from './UnlockAgentSettingModal';

type LotoOrderInfoModalProps = {
  orderId: number;
  isOpen: boolean;
  isEditable: boolean;
  onRefreshList: () => void;
  onClose: () => void;
};

export default function LotoOrderInfoModal({
  orderId,
  isOpen,
  isEditable,
  onRefreshList,
  onClose,
}: LotoOrderInfoModalProps) {
  const { data, refetch: refetchOrder } = useQuery({
    queryKey: userKeys.detail(orderId),
    queryFn: () => getLotoOrder(orderId),
    enabled: false,
  });

  useEffect(() => {
    if (orderId > 0) {
      refetchOrder();
    }
  }, [orderId]);

  const mutationOption = {
    onSuccess: (resp: BaseResp) => {
      if (resp.code === 200) {
        refetchOrder();
        onRefreshList();
      } else {
        alert(resp.message);
      }
    },
  };
  const confirmAttachMutation = useConfirmAttachMutation();
  const cancelConfirmAttachMutation = useCancelConfirmAttachMutation();
  const approveDeleteMutation = useApproveDeleteMutation();
  const cancelApproveDeleteMutation = useCancelApproveDeleteMutation();
  const confirmDeleteMutation = useConfirmDeleteMutation();

  const isAllUnlocked = (conclusionList: Locking[]): boolean => {
    if (conclusionList.length === 0) {
      return false;
    }

    return conclusionList.every((conclusion) => conclusion.unlockRegDate);
  };

  const isExistUnlock = (lockingList: Locking[]) =>
    lockingList.some((info) => info.unlockRegDate);

  const popupRef = useRef<HTMLDivElement>(null);
  const makeEmptyRowByCount = (totalRowCount: number, startIndex: number) => {
    const MAX_ROW_COUNT = 18;

    let emptyRows = '';
    for (let i = 0; i < MAX_ROW_COUNT - totalRowCount; i += 1) {
      emptyRows += `
      <tr>
        <td>${startIndex + i + 1}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>`;
    }

    return emptyRows;
  };
  const onPrint = (totalRowCount: number) => {
    const printContents = popupRef.current?.innerHTML;
    const windowObject = window.open(
      '',
      'PrintWindow',
      'width=800, height=600, top=200, left=200, toolbars=no, scrollbars=no, status=no, resizable=no',
    );

    if (!printContents || !windowObject) {
      return;
    }

    const content = printContents
      .replaceAll('  ', '')
      .replaceAll('max-h-22dot5', '');
    const containContent =
      content.slice(0, content.lastIndexOf('</tr>')) +
      makeEmptyRowByCount(totalRowCount, totalRowCount) +
      content.slice(content.lastIndexOf('</tr>'));

    windowObject.document.open();
    windowObject.document.title =
      `${data?.info?.orderNo}(${data?.info.name})` ?? new Date().toString();
    windowObject.document.write(...generatePopupContent(containContent));
    windowObject.document.close();
    windowObject.onload = () => {
      setTimeout(() => windowObject.print(), 500);

      windowObject.onafterprint = () => {
        windowObject.close();
      };
    };
  };

  const handleOrderEventByOrderEventType = (type: ORDER_EVENT_TYPE) => {
    const info = data?.info;
    if (!info) {
      return;
    }

    const { lockConfirmDate, approveDate, unlockConfirmDate, lockings } = info;

    switch (type) {
      case ORDER_EVENT_TYPE.ATTACHMENT_CONFIRM:
        if (
          !lockConfirmDate &&
          !(approveDate ?? false) &&
          !(unlockConfirmDate ?? false)
        ) {
          confirmAttachMutation.mutate(orderId, mutationOption);
        }
        break;
      case ORDER_EVENT_TYPE.ATTACHMENT_CONFIRM_CANCEL:
        if (
          lockConfirmDate &&
          !(approveDate ?? false) &&
          !(unlockConfirmDate ?? false)
        ) {
          cancelConfirmAttachMutation.mutate(orderId, mutationOption);
        }
        break;
      case ORDER_EVENT_TYPE.DELETE_APPROVE:
        if (
          lockConfirmDate &&
          !(approveDate ?? false) &&
          !(unlockConfirmDate ?? false)
        ) {
          approveDeleteMutation.mutate(orderId, mutationOption);
        }
        break;
      case ORDER_EVENT_TYPE.DELETE_APPROVE_CANCEL:
        if (
          lockConfirmDate &&
          (approveDate ?? false) &&
          !(unlockConfirmDate ?? false)
        ) {
          cancelApproveDeleteMutation.mutate(orderId, mutationOption);
        }
        break;
      case ORDER_EVENT_TYPE.DELETE_CONFIRM:
        if (
          lockConfirmDate &&
          (approveDate ?? false) &&
          !(unlockConfirmDate ?? false) &&
          isAllUnlocked(lockings ?? [])
        ) {
          confirmDeleteMutation.mutate(orderId, mutationOption);
        }
        break;
      case ORDER_EVENT_TYPE.PRINT:
        if (
          lockConfirmDate &&
          (approveDate ?? false) &&
          (unlockConfirmDate ?? false)
        ) {
          onPrint(data.info.lockings.length);
        }
        break;
      default:
        console.log('');
    }
  };

  const [isOpenUnlockAgentSettingModal, setIsOpenUnlockAgentSettingModal] =
    useState<boolean>(false);
  const [lockInfo, setLockInfo] = useState<Locking | null>(null);
  const onOpenSetAgentModal = (locking: Locking) => {
    setIsOpenUnlockAgentSettingModal(true);
    setLockInfo(locking);
  };

  const onCloseSetAgentModal = () => {
    setIsOpenUnlockAgentSettingModal(false);
    setLockInfo(null);
  };

  const setUnlockAgentMutation = useSetUnlockAgentMutation();
  const handleSettingUnlockAgent = (originId: number, transferId: number) => {
    setUnlockAgentMutation.mutate(
      { orderId, originId, transferId },
      {
        onSuccess: (resp) => {
          if (resp.code === 200) {
            onCloseSetAgentModal();
            refetchOrder();
          } else {
            alert(resp.message);
          }
        },
      },
    );
  };

  return (
    <>
      <ReactModal isOpen={isOpen} style={customStyles} onRequestClose={onClose}>
        {/* 작업허가서 승인 사인 */}
        <div className={'pop-wrap order-pop'} ref={popupRef}>
          <div className={'pop-header'}>
            <h2 className={'t-center'}>
              <span>{'잠금 및 화면 꼬리표 관리대장(LOTO)'}</span>
            </h2>
          </div>
          <div className={'pop-body'}>
            <div className={'title'}>
              <p className={'sub-title'}>{'(LOTO: Lock Out Tag Out)'}</p>
              <p className={'desc'}>
                {
                  '*본 관리대장은 규정에 의거 제거일로부터 1개월간 보관을 원칙으로 한다. 단 꼬리표가 부착되어 있는 경우 계속하여 관리한다.'
                }
              </p>
            </div>
            {data?.info && (
              <>
                <LotoOrderInfo order={data?.info} />

                <section className={'mgt-2rem'}>
                  <div className={'sub-title table-wrap-title'}>
                    <h3>{'체결 내역'}</h3>
                    <div className={'btn-group no-print'}>
                      {isEditable && (
                        <>
                          {data?.info.lockConfirmDate &&
                          !data?.info.approveDate ? (
                            <button
                              type={'button'}
                              className={'btn-type-01'}
                              onClick={() =>
                                handleOrderEventByOrderEventType(
                                  ORDER_EVENT_TYPE.ATTACHMENT_CONFIRM_CANCEL,
                                )
                              }
                            >
                              {'부착확정 취소'}
                            </button>
                          ) : (
                            <button
                              type={'button'}
                              className={'btn-type-01'}
                              onClick={() =>
                                handleOrderEventByOrderEventType(
                                  ORDER_EVENT_TYPE.ATTACHMENT_CONFIRM,
                                )
                              }
                              disabled={!!data?.info.lockConfirmDate}
                            >
                              {'부착확정'}
                            </button>
                          )}
                          {data?.info.approveDate &&
                          data.info.lockConfirmDate &&
                          !data.info.unlockConfirmDate &&
                          !isExistUnlock(data.info.lockings ?? []) ? (
                            <button
                              type={'button'}
                              className={'btn-type-01'}
                              onClick={() =>
                                handleOrderEventByOrderEventType(
                                  ORDER_EVENT_TYPE.DELETE_APPROVE_CANCEL,
                                )
                              }
                            >
                              {'제거승인취소'}
                            </button>
                          ) : (
                            <button
                              type={'button'}
                              className={'btn-type-01'}
                              onClick={() =>
                                handleOrderEventByOrderEventType(
                                  ORDER_EVENT_TYPE.DELETE_APPROVE,
                                )
                              }
                              disabled={
                                !data?.info.lockConfirmDate ||
                                !!data?.info.unlockConfirmDate ||
                                isExistUnlock(data.info.lockings ?? [])
                              }
                            >
                              {'제거승인'}
                            </button>
                          )}
                          <button
                            type={'button'}
                            className={'btn-type-01'}
                            onClick={() =>
                              handleOrderEventByOrderEventType(
                                ORDER_EVENT_TYPE.DELETE_CONFIRM,
                              )
                            }
                            disabled={
                              !data?.info.approveDate ||
                              !!data.info.unlockConfirmDate ||
                              !isAllUnlocked(data.info.lockings ?? [])
                            }
                          >
                            {'제거확정'}
                          </button>
                        </>
                      )}
                      <button
                        type={'button'}
                        className={'btn-type-01'}
                        onClick={() =>
                          handleOrderEventByOrderEventType(
                            ORDER_EVENT_TYPE.PRINT,
                          )
                        }
                        disabled={!data?.info.unlockConfirmDate}
                      >
                        {'출력'}
                      </button>
                    </div>
                  </div>
                  <div className={'max-h-22dot5rem'}>
                    <LotoOrderConclusionTable
                      dataList={data?.info.lockings ?? []}
                      isUnlockApprove={data.info.state === 'APPROVED'}
                      onOpenAgentSettingModal={onOpenSetAgentModal}
                    />
                  </div>
                </section>
              </>
            )}
          </div>
          <div className={'pop-bottom input-large no-print'}>
            <button type={'button'} className={'btn-type-02'} onClick={onClose}>
              {'닫기'}
            </button>
          </div>
        </div>
      </ReactModal>
      {data?.info.state === 'APPROVED' && (
        <UnlockAgentSettingModal
          orderId={orderId}
          isOpen={isOpenUnlockAgentSettingModal}
          locking={lockInfo}
          onSubmit={handleSettingUnlockAgent}
          onClose={onCloseSetAgentModal}
        />
      )}
    </>
  );
}
